import { createContext, useContext, useEffect, useState } from 'react';
import { loginRequest } from '../api/login';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const login = async (token) => {
    try {
      const { data: user } = await loginRequest(token);

      if (user.code && user.code === 'NO_PLANET') {
        alert('Oops, aucune planète associé. Fais un coucou à Thomas ;)');
      } else {
        setUser(user);
        localStorage.setItem('token', token);
      }
    } catch (e) {
      setUser(null);
      localStorage.removeItem('token');
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      login(token);
    } else {
      setUser(null);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        login,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
