import React from 'react';
import { useAuth } from '../context/AuthContext';
import { PLANETS } from '../constants';

const Cell = ({ cell, isHighlight, onClick }) => {
  const { user } = useAuth();
  const getPlanet = (planetId) => {
    return PLANETS[planetId];
  };

  return (
    <div
      onClick={onClick}
      className='cell'
      style={{
        backgroundColor: getPlanet(cell.planetId).color,
        color: 'black',
        cursor: isHighlight || user.planetId === cell.planetId ? 'pointer' : '',
        fontSize: '12px',
        margin: '1px',
        border: isHighlight ? '2px solid #8C52FF ' : 'none', // Surbrillance des cellules valides
      }}
      data-tooltip-html={`${getPlanet(cell.planetId).name}<br/> Unité(s): ${cell.units}`}
    >
      {cell.units}
    </div>
  );
};

export default Cell;
