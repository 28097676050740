import { GoogleLogin } from '@react-oauth/google';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const Login = () => {
  const { user, login } = useAuth();

  if (user) {
    return <Navigate replace to='/' />;
  }

  const handleGoogleResponse = (response) => {
    login(response.credential);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <div style={{ zIndex: '99' }}>
        <GoogleLogin
          onSuccess={handleGoogleResponse}
          render={(renderProps) => (
            <button
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              This is my custom Google button
            </button>
          )}
          buttonText='Login'
        />
      </div>
    </div>
  );
};

export default Login;
