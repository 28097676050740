import { useQuery } from '@tanstack/react-query';
import Board from './Board';
import { getGameInfos } from '../api/game';
import { useAuth } from '../context/AuthContext';
import Header from './Header';

const Home = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['gameInfo'],
    queryFn: () => getGameInfos(),
  });

  const { user } = useAuth();
  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error loading game info</p>;

  const conquish = data.gameSession.board.filter(
    (cell) => cell.planetId === user.planetId
  );
  const actionsLeft = data.remainingActions;
  console.log(data);
  return (
    <div className='app'>
      <Header
        conquish={conquish.length}
        actionsLeft={actionsLeft}
        gameTurn={data.gameSession.gameTurn}
      />
      <Board board={data.gameSession.board} actionsLeft={actionsLeft} />
    </div>
  );
};

export default Home;
