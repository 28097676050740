import React, { useRef } from 'react';

const NumberPopup = ({ onClose, handleAction, label, action }) => {
  const unitRef = useRef(null);

  return (
    <div className='popup'>
      <div className='popup-content'>
        <h2>{label}</h2>
        <input type='number' min='1' max='100' ref={unitRef} />
        <button
          onClick={() => {
            handleAction(unitRef.current.value);
          }}
        >
          {action}
        </button>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default NumberPopup;
