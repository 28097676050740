import React, { useRef, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Tooltip } from 'react-tooltip';

import Cell from './Cell';
import Popup from './Popup';
import NumberPopup from './NumberPopup';
import ErrorPopup from './ErrorPopup';
import { fetchIsValidCells, move, reinforcedCell } from '../api/game';
import { useAuth } from '../context/AuthContext';

const ACTION = {
  NOTHING: 0,
  SELECTED: 1,
  REINFORCED: 2,
  PREPARE_MOVE: 3,
  MOVE: 4,
};

const Board = ({ board, actionsLeft, gameTurn }) => {
  const [selectedCell, setSelectedCell] = useState(null);
  const [action, setAction] = useState(ACTION.NOTHING);
  const [highlightedCells, setHighlightCells] = useState([]);
  const [error, setError] = useState(null);
  const { user } = useAuth();
  const unitToMove = useRef(0);

  const queryCLient = useQueryClient();

  const reinforcedMutation = useMutation({
    mutationFn: reinforcedCell,
    onSuccess: () => {
      queryCLient.invalidateQueries({ queryKey: ['gameInfo'] });
      handleReset();
    },
    onError: (e) => {
      handleReset();
      console.log(e);
      if (e?.response?.data?.message) {
        setError(e?.response?.data?.message);
      } else {
        setError('Une erreur est survenue');
      }
    },
  });

  const moveMutation = useMutation({
    mutationFn: move,
    onSuccess: () => {
      queryCLient.invalidateQueries({ queryKey: ['gameInfo'] });
      handleReset();
    },
    onError: (e) => {
      handleReset();
      console.log(e);
      if (e?.response?.data?.message) {
        setError(e?.response?.data?.message);
      } else {
        setError('Une erreur est survenue');
      }
    },
  });

  // Handle cell click to show popup
  const handleCellClick = ({ id, planetId }) => {
    if (actionsLeft < 1) {
      return;
    }
    if (action !== ACTION.MOVE) {
      if (user.planetId !== planetId) {
        return;
      }
      setSelectedCell(id);
      setAction(ACTION.SELECTED);
    } else {
      if (!highlightedCells.includes(id)) {
        return;
      }
      moveMutation.mutate({
        cellFromId: selectedCell,
        cellToId: id,
        units: parseInt(unitToMove.current),
      });
    }
  };

  const handleReinforceClick = () => {
    setAction(ACTION.REINFORCED);
  };

  const handleMoveClick = async () => {
    setAction(ACTION.PREPARE_MOVE);
  };

  const handleMove = async (units) => {
    setAction(ACTION.MOVE);
    unitToMove.current = units;
    const x = await fetchIsValidCells(selectedCell);
    if (x.adjacentCellIds) {
      setHighlightCells(x.adjacentCellIds);
    }
  };

  const handleReset = () => {
    setSelectedCell(null);
    setAction(ACTION.NOTHING);
    setHighlightCells([]);
    setError(null);
  };

  const handleReinforce = (units) => {
    reinforcedMutation.mutate({
      cellId: selectedCell,
      units: parseInt(units),
    });
  };

  return (
    <div>
      <div className='board'>
        <Tooltip
          anchorSelect='.cell'
          place='top'
          className='tooltip tooltip-cell'
        />
        {board.map((cell, index) => (
          <Cell
            key={index}
            cell={cell}
            onClick={() => {
              handleCellClick(cell);
            }}
            isHighlight={highlightedCells.includes(cell.id)}
          />
        ))}
      </div>
      {selectedCell && action === ACTION.SELECTED && (
        <Popup
          onReinforce={handleReinforceClick}
          onMove={handleMoveClick}
          onClose={handleReset} // Close popup
        />
      )}
      {selectedCell && action === ACTION.REINFORCED && (
        <NumberPopup
          cellId={selectedCell}
          onClose={handleReset}
          handleAction={handleReinforce}
          label='Renforcer la cellule'
          action='Renforcer'
        />
      )}
      {selectedCell && action === ACTION.PREPARE_MOVE && (
        <NumberPopup
          cellId={selectedCell}
          onClose={handleReset}
          handleAction={handleMove}
          label='Deplacer des unitées'
          action='Deplacer'
        />
      )}
      {error && <ErrorPopup onClose={handleReset} error={error} />}
    </div>
  );
};

export default Board;
