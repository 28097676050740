import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/globals.css'; // Global styles
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from './App';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './context/AuthContext';

// Create the React Query client
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
const googleClientID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

root.render(
  <GoogleOAuthProvider clientId={googleClientID}>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </QueryClientProvider>
  </GoogleOAuthProvider>
);
