import React from 'react';
import '../styles/header.css'; // Assurez-vous de créer ce fichier CSS
import { useAuth } from '../context/AuthContext';
import { Tooltip } from 'react-tooltip';
import { PLANETS } from '../constants';

const Header = ({ conquish, gameTurn, actionsLeft }) => {
  const { user } = useAuth();

  return (
    <header className='header'>
      {/* Conteneur gauche */}
      <Tooltip
        anchorSelect='.left-container'
        place='bottom'
        className='tooltip tooltip-header'
      />
      <div
        className='left-container'
        data-tooltip-content='Action restantes pour ce tour'
      >
        <img
          src='https://via.placeholder.com/50'
          alt='Left Icon'
          className='left-image'
        />
        <div className='left-box'>
          <span className='left-text'>{actionsLeft}</span>
        </div>
      </div>

      {/* Conteneur central */}
      <div className='center-container'>
        <div className='center-box'>
          <div className='center-main-text'>TOUR {gameTurn}</div>
          <div
            className='center-sub-text'
            style={{
              color: PLANETS[user.planetId].color,
            }}
          >
            {PLANETS[user.planetId].name}
          </div>
        </div>
      </div>

      {/* Conteneur droit */}
      <Tooltip
        anchorSelect='.right-container'
        place='bottom'
        className='tooltip tooltip-header'
      />
      <div
        className='right-container'
        data-tooltip-content='Cellules controlées par la planete'
      >
        <img
          src='https://via.placeholder.com/50'
          alt='Right Icon'
          className='right-image'
        />
        <div className='right-box'>
          <span className='right-text'>{conquish}</span>
        </div>
      </div>
    </header>
  );
};

export default Header;
